<template>
  <div class="text-center">
    <b-row class="pricing-card">
      <b-col sm="12" md="10" offset-lg="4" lg="6" class="mx-auto">
        <b-card no-body class="card-employee-task">
          <div class="text-center">
            <b-img
              class="text-center mx-auto"
              style="width: 35%"
              :src="require('@/assets/images/channels/refuse_requirements.svg')"
            />
          </div>

          <b-card-body class="px-2" v-if="showLoading && !showError">
            <h3 class="mb-2">{{ $t("Pages.Channel.Title.please_wait") }}</h3>
            <b-spinner type="grow" label="Loading..."></b-spinner>
            <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" block variant="outline-primary" class="mt-2" @click="backToDashboard">
              {{ $t("Pages.Channel.Button.Back To Dashboard") }}
            </b-button> -->
          </b-card-body>
          <b-card-body class="px-2" v-else-if="!showLoading && !showError">
            <h3 class="mb-2">
              {{ $t("Pages.Channel.Title.welcome_dashboard") }}
            </h3>
            <b-media left-align vertical-align="top" class="text-left">
              <template #aside>
                <b-img
                  :src="channel.avatar"
                  blank-color="#ccc"
                  width="64"
                  alt="placeholder"
                  rounded="circle"
                />
              </template>
              <h3 class="media-heading" style="margin-top: 3px">
                {{ channel.nickname }}
              </h3>
              <b-card-text class="mb-0">{{ channel.description }}</b-card-text>
            </b-media>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              block
              variant="outline-primary"
              class="mt-2"
              @click="backToDashboard"
            >
              {{ $t("Pages.Channel.Button.back_dashboard") }}
            </b-button>
          </b-card-body>
          <b-card-body class="px-2" v-if="!showLoading && showError">
            <h3 class="mb-2 text-primary">
              {{
                $t(
                  "Pages.Channel.Title.req_error_contact_expert"
                )
              }}
            </h3>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BSpinner,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BMediaBody,
  BMedia,
  BImg,
} from "bootstrap-vue";
export default {
  name: "addChannel.vue",
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMediaBody,
    BMedia,
    BImg,
  },
  data() {
    return {
      channel: {},
      showLoading: true,
      showError: false,
    };
  },
  methods: {
    backToDashboard() {
      this.$router.push({ name: "channels" });
    },
    getChannelData() {
      this.$http
        .get(`/api/channel/${this.channel_id}/add/data`)
        .then((response) => {})
        .catch((error) => {
          if (error.response.status !== 401) {
            this.$router.push("/channels").catch(() => {});
          }
        });
    },
  },
  created() {
    this.$http
      .get(`/api/channel/add/back`, {
        params: {
          code: this.$route.query.code,
          state: this.$route.query.state,
          scope: this.$route.query.scope,
        },
      })
      .then((response) => {
        if (response) {
          this.channel = response.data;
          this.getChannelData();
          this.showLoading = false;
        } else {
          this.showLoading = true;
        }

        if (response.status == 500) {
          this.showError = true;
        }
      })
      .catch((error) => {
        if (error.response.status == 500) {
          this.showError = true;
        }

        if (error.response.status !== 401) {
          this.$router.push("/channels").catch(() => {});
        }
      });
  },
};
</script>

<style scoped>
.fix-height {
  height: 50px;
}
</style>
